<template>
	<div>
		<!-- 
	  bordered :边框线
	  columns：表头
	  slots: { title: '是否显示' }, //插槽列
	  scopedSlots: { customRender: '是否显示' },//插槽列数据
	  @change="handleTableChange" //分页、排序、筛选变化时触发
	  -->
		<a-table 
		bordered 
		:columns="columns" 
		:data-source="datasource" 
		:pagination="pagination" 
		row-key="id"
		@change="handleTableChange"
		>
		
			<template  slot="icon" slot-scope="icon">
				
				<img class="img-pic" :src="icon" alt="" />
				
			</template>	
		
			<template slot="sort" slot-scope="text,record">
				<div class="set f-ajc">
					<template v-for="(item,index) in record.sort">
						<div class="setitem f-ac">
<!-- 							<div class="sort">
								<span class="txt">{{"排序"}}</span>
								<a-input class="a-input" placeholder="序号" />
							</div> -->
							<div class="switch" @click="getshow(record,item)">
								<span class="txt">{{item.name}}</span>
								<template v-if="item.status == 1">
									<a-switch class="a-switch" default-checked @change="onchange"/>
								</template>
								<template v-else>
									<a-switch class="a-switch" @change="onchange"/>
								</template>
							</div>
						</div>
					</template>
				</div>
			</template>
			
			<template slot="action" slot-scope="text,record">
				<a-button  type="primary" @click="modify(record)">
					{{"修改"}}
				</a-button>
			</template>

		</a-table>
	</div>
</template>
<script>
	export default {
		props: {
			columns: Array,
			datasource: Array,
			pagination: Object,
			
		},
		data() {
			return {
				show:{
					title:"确认禁止",
					txt:"显示"
				},
			};
		},
		mounted() {

		},
		methods: {
			// 分页点击触发
			handleTableChange(pagination, filters, sorter) {
			  console.log(pagination);
			 this.$emit("gettabledata",pagination)
			},
			// 开关器
			getshow(record,item){
				let records = {
					record:record,
					item:item
				}
				this.$emit('getshow',records)
			},
			// 开关变化
			onchange(e){
				console.log(e,"开关点击");
			},
			// 打开操作框
			modify(record) {
				this.$emit("modify", record)
			},
			// 删除操作
			del(record){
				this.$emit("del", record)
			}
		},
	};
</script>

<style lang="less" scoped>


	.set {
		flex-direction: column;

		.setitem {
			width: 100%;

			.sort {
				width: 40%;

				.a-input {
					width: 50px;
					padding-left: 10px;
					margin-left: 5px;
				}
			}

			.switch {
				flex: 1;

				.txt {}

				.a-switch {
					margin-left: 5px;
					padding-left: 10px;
				}
			}
		}
	}
</style>
