<template>
	<div class="content">
		<a-button type="primary" @click="handleAdd">
		  新增长期卡
		</a-button>
		<a-table :pagination="false" :columns="cyclecolumns" :data-source="cycledata"
			:row-key="(record, index) => index">
			<template v-for="col in ['name', 'week', 'discount','univalent']" :slot="col"
				slot-scope="text, record, index">
				<div :key="col">
					<a-input  style="margin: -5px 0"  v-model="text"
						@change="e => handleChange(e.target.value, record, col,index)" />
<!-- 					<template v-else>
						{{ text }}
					</template> -->
				</div>
			</template>
			<template slot="action" slot-scope="text, record, index">
				<div class="editable-row-operations">
					<a-button type="danger"  :disabled="editingKey !== ''" @click="() => onDelete(record,index)">删除</a-button>
<!-- 					<div v-if="record.editable">
						<a-button type="primary" @click="() => save(record,index)">保存</a-button>
						<a-popconfirm title="Sure to cancel?" @confirm="() => cancel(record,index)">
							<a-button type="danger" class=" mt-30">删除</a-button>
						</a-popconfirm>
					</div>
					<div v-else>
						<a-button type="primary" :disabled="editingKey !== ''" @click="() => edit(record,index)">修改</a-button>
		
						<a-button type="danger" class=" mt-30" :disabled="editingKey !== ''" @click="() => onDelete(record,index)">删除</a-button>
		
					</div> -->
				</div>
			</template>
		</a-table>
	</div>
</template>

<script>
	export default {
		props:{
			cycledata:Array,
			record:Object,
			index:Number,
			indexs:Number
		},
		data() {
			return {
				editingKey:"",
				cyclecolumns: [{
						title: '名称',
						dataIndex: 'name',
						scopedSlots: {
							customRender: 'name'
						},
					},
					{
						title: '周',
						dataIndex: 'week',
						scopedSlots: {
							customRender: 'week'
						},
					},
					{
						title: '折扣',
						dataIndex: 'discount',
						scopedSlots: {
							customRender: 'discount'
						},
					},
					{
						title: '单次金额',
						dataIndex: 'univalent',
						scopedSlots: {
							customRender: 'univalent'
						},
					},
					{
						title: '操作',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						},
					}
				],
			}
		},
		created() {
			console.log(this.cycledata,this.record,this.index,"cycle数据");
		},
		methods: {
			// 日常保洁方法
			// 长期卡新增
			handleAdd(){
				const dataSource = [...this.record.data.specificationAll[this.indexs].complex[this.index].cycle]
				// let {name,week,discount,univalent} = dataSource[dataSource.length-1]
				let obj ={
					name: '',
					week: '',
					discount: '',
					univalent: '',
				}
				// obj.key++
				if(!obj){
					obj={
					    name: '',
					    week: '',
					    discount: '',
					    univalent: '',
					}
				}
				this.record.data.specificationAll[this.indexs].complex[this.index].cycle.push(obj)
				console.log(this.record.data.specificationAll[this.indexs].complex[this.index].cycle,"增加长期卡规格item");
			},
			// 修改变动赋值
			handleChange(value, key, column,index) {
				const newData = [...this.record.data.specificationAll[this.indexs].complex[this.index].cycle];
				const target = newData[index];
				if (target) {
					target[column] = value;
					this.record.data.specificationAll[this.indexs].complex[this.index].cycle = newData;
				}
			},
			
			edit(key,index) {
				// console.log(index,this.index,"长期卡当前index");
				const newData = [...this.record.data.specificationAll[this.indexs].complex[this.index].cycle];
				const target = newData[index];
				console.log(index,"长期卡选中");
				this.editingKey = key;
				if (target) {
					target.editable = true;
					this.record.data.specificationAll[this.indexs].complex[this.index].cycle = newData;
				}
			},
			save(key,index) {
				console.log(index,this.index,"长期卡保存index");
				const newData = [...this.record.data.specificationAll[this.indexs].complex[this.index].cycle];
				this.cacheData = this.record.data.specificationAll[this.indexs].complex[this.index].cycle.map(item => ({
					...item
				}));
				const newCacheData = [...this.cacheData];
				const target = newData[index];
				const targetCache = newCacheData[index];
				if (target && targetCache) {
					delete target.editable;
					delete targetCache.editable;
					this.record.data.specificationAll[this.indexs].complex[this.index].cycle = newData;
					Object.assign(targetCache, target);
					this.cacheData = newCacheData;
				}
				this.editingKey = '';
				this.record.data.specificationAll[this.indexs].complex[this.index].cycle = this.cacheData
				console.log(this.cacheData);
			},
			cancel(key,index) {
				const newData = [...this.record.data.specificationAll[this.indexs].complex[this.index].cycle];
				this.cacheData = this.record.data.specificationAll[this.index].complex.map(item => ({
					...item
				}));
				const target = newData[index];
				this.editingKey = '';
				if (target) {
					Object.assign(target, this.cacheData[index]);
					delete target.editable;
					this.record.data.specificationAll[this.indexs].complex[this.index].cycle= newData;
				}
			},
			onDelete(key,index) {
				const dataSource = [...this.record.data.specificationAll[this.indexs].complex[this.index].cycle];
				this.record.data.specificationAll[this.indexs].complex[this.index].cycle.splice(index,1)
			},
		}
	}
</script>

<style>
</style>
