<template>
	<div class="content">
		<template v-if="istable">
			<!-- 添加分类 -->
			<a-button class="mb-15" type="primary" @click="add">
			  添加商品
			</a-button>
			<!-- 搜索 -->
			<a-card class="acard f-ac">
				<div class="f-ac">
					<div class="f-ac">
						<div class="tips">关键词：</div>
						<a-input-search class="ainputsearch" placeholder="请输入搜索内容" enter-button
							@search="onSearch" />
					</div>
				</div>
			</a-card>
			<!-- 表格 -->
			<Table
			:columns="columns" 
			:datasource="datasource" 
			:pagination="pagination"
			@gettabledata="gettabledata" 
			@getshow = "getshow"
			@modify = "modify"
			@del = "del"
			>
			</Table>
		</template>
		
		<!-- 修改 -->
		<Modify ref="modify" :record="record" @changeimg = "changeimg" @modifynav="modifynav" @cancelss="cancelss"></Modify>
		
		<!-- Add -->
		<Add ref="add" :record="record" @changeimg = "changeimg" @modifynav="addnav" @cancelss="cancelss"></Add>
	</div>
</template>

<script>
	import {mapState,mapGetters,mapMutations,mapActions} from "vuex"
	import Table from "../../components/goods/list/table.vue"
	import Modify from "../../components/goods/list/modify.vue"
	import Add from "../../components/goods/list/add.vue"
	export default {
		components:{
			Table,
			Modify,
			Add
		},
		data() {
			return {
				istable:true,
				record:{
					data:"",
					shop:"",
					classify:"",
					unit:"",
					area:"",
					unitAll:""
				},
				pagination:{
					pageSize:4,
					current:1,
					totalPage:0,
					total:0,
					keyword:"",
					platform:""
				},
				columns :[
				  {
				    title: 'ID',
					key:"id",
				    dataIndex: 'id',
					align: 'center' 
				  },
				  {
				    title: '商品名称',
					key:"name",
				    dataIndex: 'name',
					align: 'center' 
				  },
				  {
				    title: '商品图片',
				  	key:"icon",
				    dataIndex: 'icon',
					slots: { title: 'icon' }, //插槽列
					scopedSlots: { customRender: "icon" },//插槽列数据
					align: 'center' 
				  },
				  {
				   title: '设置（排序值越小越靠前）',
				   key:"sort",
				   dataIndex: 'sort',
				   slots: { title: 'sort' }, //插槽列
				   scopedSlots: { customRender: "sort" },//插槽列数据
					align: 'center' 
				  },
				  {
				    title: '创建时间',
					key:"create_time",
				    dataIndex: 'create_time',
					align: 'center' 
				  },
				  {
				    title: '创建人',
					key:"create_user",
				    dataIndex: 'create_user',
					align: 'center' 
				  },
				  {
				    title: '操作',
					key:"action",
				    dataIndex: 'action',
					slots: { title: 'action' }, //插槽列
					scopedSlots: { customRender: "action" },//插槽列数据
					align: 'center' 
				  },
				],
				datasource:[
					{
						sort:1,
						id:1,
						name:"家电清洗",
						classify:"服务",
						icon:"icon",
						show:false,
						time:"2012-03-34",
						person:"马先生",
						control:"修改"
					},
					{
						sort:2,
						id:2,
						name:"家电产生的的清洗",
						classify:"服务",
						icon:"icon",
						show:true,
						time:"2012-03-34",
						person:"重先生",
						control:"修改"
					},
				]
			}
		},
		created() {
			// 获取表格数据
			this.gettabledata(this.pagination)
		},
		methods:{
			...mapActions({
				getgoods:"goodslist/getgoods",
				getstatus:"goodslist/getstatus",
				getupdate:"goodslist/getupdate",
				getadd:"goodslist/getadd",
				getdel:"goodslist/getdel",
				commonclass:"index/commonclass",
				getallclass:"index/getallclass"
			}),
			/*
			 *获取数据
			 *pagination 分页参数
			 * 初始化和点击执行
			 */
			async gettabledata(pagination){
				 let data = await this.getgoods(pagination)
				 this.pagination.current = pagination.current
				 this.pagination.total = data.data.total
				 this.pagination.totalPage = data.data.totalPage
				 this.datasource = data.data.data
				 this.record.unit = data.ser_unitAll
				 this.record.unitAll = data.unitAll
				 this.record.area = data.areaAll
			
			},
			// onSearch
			onSearch(e){
				console.log(e,"关键字");
				this.pagination.keyword = e
				this.pagination.current = 1
				this.gettabledata(this.pagination)
			},
			// 显示和禁止
			async getshow(record){
				console.log("获取行列表和选择开关",record);
				let type = ""
				if(record.item.name == "上架"){
					type = 1
				}else if (record.item.name == "首页推荐"){
					type = 2
				}else{
					type = 3
				}
				let payload = {
					id:record.record.id,
					type:type
				}
				let msg = await this.getstatus(payload)
				this.$message.success(msg)
				//刷新数据
				this.gettabledata(this.pagination)
			},
			// 打开修改弹窗
			async modify(record){
				console.log("record",this.record);
				let classify = await this.commonclass('two_category')
				console.log("所有分类",classify);
				// 每一行数据record
				this.record.classify = classify
				this.record.data = record
				this.istable = false
				this.$refs.modify.show = true
				
			},
			// 更换图片
			changeimg(payload){
				console.log(payload,"点击图片");
				if(payload.witchbtn == 1){
					this.record.data.icon = payload.val
				}else if(payload.witchbtn == 2){
					this.record.data.show_img = payload.val
				}else if(payload.witchbtn == 3){
					this.record.data.category_img = payload.val
				}else if(payload.witchbtn == 4){
					if(payload.index !=undefined){
						const arr = [...this.record.data.carousel_img]
						arr[payload.index] = payload.val
						this.record.data.carousel_img = arr
						console.log(this.record.data.carousel_img)
						
					}else{
						this.record.data.carousel_img.push(payload.val)
					}
					
				}else{
					if(payload.witchbtn == 6){
						console.log(payload.val,"富文本");
						this.record.data.details += `<img src="${payload.val}" >`
					}else{
						this.record.data.specificationAll[payload.index].multiple[payload.indexs].img = payload.val
					}
					
				}
			},
			// 打开添加弹窗
			async add(){
				console.log("record",this.record);
				let classify = await this.commonclass('two_category')
				console.log("所有分类",classify);
				// 每一行数据record
				this.record.classify = classify
				// 每一行数据record
				let record = {
					carousel_img: [],
					category_id: "",
					category_img: "",
					deposit: "1",
					desc: " ",
					details: " ",
					end_address: "",
					end_time: "8:00",
					icon: "",
					id: "",
					keyword: "",
					name: "",
					price: "",
					show_img: "",
					start_time: "8:00",
					status: "1",
					template: "alone",
					unit: "",
					specificationAll:[
						{
							alone:[
								{
									name:"",
									price:""
								}
							],
							cityAll:[]
						}
						
					]
				}
				this.record.data = record
				this.istable = false
				this.$refs.add.show = true
				
			},
			// 修改提交
			async modifynav(payload){
				console.log(payload,"提交参数");
				payload.photo_urls = JSON.stringify(payload.photo_urls)
				// 整理数据格式
				payload.specificationAll = JSON.stringify(payload.specificationAll)
				console.log(typeof(payload.specificationAll));
				payload.time = payload.start_time+'-'+payload.end_time
				let msg = await this.getupdate(payload)
				this.$message.success(msg)
				// //刷新数据
				await this.gettabledata(this.pagination)
				this.istable = true
				this.$refs.modify.show = false
			},
			// 取消提交
			async cancelss(){
				await this.gettabledata(this.pagination)
				this.istable = true
				this.$refs.modify.show = false
			},
			// 打开
			// addnav 添加提交
			async addnav(payload){
				console.log(payload,"提交参数");
				payload.photo_urls = JSON.stringify(payload.photo_urls)
				// 整理数据格式
				payload.specificationAll = JSON.stringify(payload.specificationAll)
				console.log(typeof(payload.specificationAll));
				payload.time = payload.start_time+'-'+payload.end_time
				let msg = await this.getadd(payload)
				this.$message.success(msg)
				//刷新数据
				await this.gettabledata(this.pagination)
				this.istable = true
				this.$refs.add.show = false
			},
			// 删除
			async del(e){
				// 
				console.log(e);
				let msg = await this.getdel(e.id)
				this.$message.success(msg)
				// //刷新数据
				this.gettabledata(this.pagination)
			}
		}
	}
</script>

<style>
</style>
