<template>
	<div class="content">
		<a-button type="primary" @click="handleAdd">
		  新增规格
		</a-button>
		<a-table :columns="columns" :pagination="false" :data-source="complexdata" :row-key="(record, index) => index" bordered>
			<template slot="cycle" slot-scope="text, record, index">
				<Cycle :cycledata="record.cycle" :record="records" :indexs="indexs" :index="index"></Cycle>
			</template>
			<template slot="times" slot-scope="text, record, index">
				<Times :cycledata="record.times" :record="records" :indexs="indexs" :index="index"></Times>
			</template>

			<template v-for="col in ['name', 'desc', 'discount','price']" :slot="col" slot-scope="text, record, index">
				<div :key="col">
					<!-- 绑定的v-model -->
					<a-input  style="margin: -5px 0" :v-model="text" :value="text"
						@change="e => handleChange(e.target.value, index, col)" />
<!-- 					<template v-else>
						{{ text }}
					</template> -->
				</div>
			</template>
			<template slot="operation" slot-scope="text, record, index">
				<div class="editable-row-operations">
					<a-button type="danger" :disabled="editingKey !== ''" @click="() => onDelete(index)">删除</a-button>
<!-- 					<div v-if="record.editable">
						<a-button type="primary" class="ml-30 " @click="() => save(index)">保存</a-button>
						<a-popconfirm title="确认删除吗?" @confirm="() => cancel(index)">
							<a-button type="danger" class="ml-30 mt-30">删除</a-button>
						</a-popconfirm>
					</div>
					<div v-else>
						<a-button type="primary" class="ml-30 " :disabled="editingKey !== ''" @click="() => edit(index)">修改</a-button>

						<a-button type="danger"class="ml-30 mt-30" :disabled="editingKey !== ''" @click="() => onDelete(index)">删除</a-button>

					</div> -->
				</div>
			</template>
		</a-table>
	</div>
</template>

<script>
	import Cycle from "./cycle.vue"
	import Times from "./times.vue"
	export default {
		components:{
			Cycle,
			Times
		},
		props: {
			// 日常保洁数据
			complexdata: Array,
			// data数据
			records: Object,
			// 当前规格操作index
			indexs:Number,
			count:1
		},
		data() {
			return {
				editingKey:"",
				cacheData:"",
				// 日常保洁
				columns : [
				  {
				    title: '名称',
				    dataIndex: 'name',
				    scopedSlots: { customRender: 'name' },
				  },
				  {
				    title: '描述',
				    dataIndex: 'desc',
				    scopedSlots: { customRender: 'desc' },
				  },
				  {
				    title: '优惠则扣',
				    dataIndex: 'discount',
				    scopedSlots: { customRender: 'discount' },
				  },
				  {
				    title: '单次价格',
				    dataIndex: 'price',
				    scopedSlots: { customRender: 'price' },
				  },
				  {
				    title: '长期卡',
				    dataIndex: 'cycle',
					slots: { title: 'cycle' }, //插槽列
				    scopedSlots: { customRender: 'cycle' },
				  },
				  {
				    title: '次卡',
				    dataIndex: 'times',
					slots: { title: 'times' }, //插槽列
				    scopedSlots: { customRender: 'times' },
				  },
				  {
					  title: '操作',
					  dataIndex: 'operation',
					  scopedSlots: { customRender: 'operation' },
				  },
				],
				// 长期卡列表
				cyclecolumns: [{
						title: '名称',
						dataIndex: 'name',
						scopedSlots: {
							customRender: 'name'
						},
					},
					{
						title: '周',
						dataIndex: 'week',
						scopedSlots: {
							customRender: 'week'
						},
					},
					{
						title: '折扣',
						dataIndex: 'discount',
						scopedSlots: {
							customRender: 'discount'
						},
					},
					{
						title: '单次金额',
						dataIndex: 'univalent',
						scopedSlots: {
							customRender: 'univalent'
						},
					},
					{
						title: '操作',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						},
					}
				],
				// 次卡列表
				timescolumns: [{
						title: '次数',
						dataIndex: 'times',
						scopedSlots: {
							customRender: 'times'
						},
					},
					{
						title: '折扣',
						dataIndex: 'discount',
						scopedSlots: {
							customRender: 'discount'
						},
					},
					{
						title: '单次金额',
						dataIndex: 'univalent',
						scopedSlots: {
							customRender: 'univalent'
						},
					},
					{
						title: '操作',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						},
					}
				],
			}
		},
		created() {
			console.log(this.complexdata, "传递值");
			this.complexdata.map(item=>item.key = 0)
		},
		methods: {
			// 日常保洁方法
			// 新增规格
			handleAdd(){
				const dataSource = [...this.records.data.specificationAll[this.indexs].complex]
				let obj = obj={
					    name: '',
					    desc: '',
					    discount: '',
					    price: '',
						key:0,
					    cycle: [{name: '', week: '', discount: '', univalent: ''}],
					    times: [{times: '', discount: '', univalent: ''}]
					}
				obj.key++
				if(!obj){
					obj={
					    name: '',
					    desc: '',
					    discount: '',
					    price: '',
						key:1,
					    cycle: [{name: '', week: '', discount: '', univalent: ''}],
					    times: [{times: '', discount: '', univalent: ''}]
					}
				}
				
				this.records.data.specificationAll[this.indexs].complex.push(obj)
				let temp = this.records.data.specificationAll[this.indexs].complex
				temp.map(item=>console.log(item.key,"key值"))
				

			},
			// 修改变动赋值
			handleChange(value, key, column) {
				
				const newData = [...this.records.data.specificationAll[this.indexs].complex];
				const target = newData[key];
				if (target) {
					target[column] = value;
					this.records.data.specificationAll[this.indexs].complex = newData;
				}
				console.log(this.records.data.specificationAll[this.indexs].complex,"参数值");
			},
			
			edit(key) {
				console.log(key,"index值");
				const newData = [...this.records.data.specificationAll[this.indexs].complex];
				const target = newData[key];
				this.editingKey = key;
				if (target) {
					target.editable = true;
					this.records.data.specificationAll[this.indexs].complex = newData;
				}
			},
			save(key) {
				
				const newData = [...this.records.data.specificationAll[this.indexs].complex];
				this.cacheData = this.records.data.specificationAll[this.indexs].complex.map(item => ({
					...item
				}));
				const newCacheData = [...this.cacheData];
				// console.log(newCacheData);
				const target = newData[key];
				const targetCache = newCacheData[key];
				if (target && targetCache) {
					delete target.editable;
					this.records.data.specificationAll[this.indexs].complex = newData;
					Object.assign(targetCache, target);
					this.cacheData = newCacheData;
					// console.log(this.cacheData,newData,"index值");
				}
				this.editingKey = '';
				this.records.data.specificationAll[this.indexs].complex = newData
				console.log(this.records.data.specificationAll[this.indexs].complex,"修改后的值");
			},
			cancel(key) {
				const newData = [...this.records.data.specificationAll[this.indexs].complex];
				this.cacheData = this.records.data.specificationAll[this.indexs].complex.map(item => ({
					...item
				}));
				const target = newData[key];
				this.editingKey = '';
				if (target) {
					Object.assign(target, this.cacheData[key])
					delete target.editable;
					this.records.data.specificationAll[this.indexs].complex = newData;
				}
			},
			onDelete(key) {
				const dataSource = [...this.records.data.specificationAll[this.indexs].complex];
				this.records.data.specificationAll[this.indexs].complex.splice(key,1);
				console.log(this.records.data.specificationAll[this.indexs].complex,key,"删除值");
			},
		}
	}
</script>

<style>
</style>
