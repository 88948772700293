<template>
	<div>
		<template v-if="show">
			<div   >
				<template>
					<!-- 表单验证 -->
					<a-form ref="collectionForm" :form="form">
						
						<!-- 分类 -->					
						<template v-if="record.classify">
							<a-form-item label="分类">
								<a-select @change="getselect" v-decorator="[
									'category_id',
									{ 
										initialValue: record.classify[0].children[0].id,
										rules: [{ required: true, message: '请填写'}] 
									},
								  ]">
								  <a-select-opt-group v-for="(item,index) in record.classify" :label="item.name">
									  <a-select-option v-for="(items,indexs) in item.children"  :key="items.id">
									  	{{items.name}}
									  </a-select-option>
								  </a-select-opt-group >
									
						
								</a-select>
							</a-form-item>
						</template>
			
			
						<!-- 名称 -->
						<a-form-item label="名称">
							<a-input v-decorator="[
								'name',
								{
								  initialValue: record.data.name,
								  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
								},
							  ]" />
						</a-form-item>
						
						<!-- 单图展示区域 -->
						<div class="f-acjsb">
							<!-- 图片icon -->
							<a-form-item class="flexwith" label="点击更换icon(68X68)">
								<template v-if="record.data.icon">
									<img v-decorator="[
										'icon',
										{
										  initialValue: record.data.icon,
										  rules: [{ required: true, message: `请填写`, whitespace: true }],
										},
									  ]" style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.icon" alt="" @click="openresources(1)">
								</template>
								<template v-else>
									<div v-decorator="[
										'icon',
										{
										  initialValue: record.data.icon,
										  
										},
									  ]"
									style="width:35%;height: 30%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(1)">
										<a-icon type="plus" />
										<div class="ant-upload-text">
											上传图片
										</div>
									</div>
								</template>
							</a-form-item>
							
							<!-- 展示图片record.data.show_img -->
							<a-form-item class="flexwith" label="上传展示图片(300X220)">
								<template v-if="record.data.show_img">
									<img v-decorator="[
										'show_img',
										{
										  initialValue: record.data.show_img,
										  rules: [{ required: true, message: `请填写`, whitespace: true }],
										},
									  ]" 
									  style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.show_img" alt="" @click="openresources(2)">
								</template>
								<template v-else>
									<div v-decorator="[
										'show_img',
										{
										  initialValue: record.data.show_img,
										  
										},
									  ]"
									style="width:35%;height: 30%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(2)">
										<a-icon type="plus" />
										<div class="ant-upload-text">
											上传图片
										</div>
									</div>
								</template>
							</a-form-item>
							
							
							
							<!-- 分类图片 -->
							<a-form-item class="flexwith" label="上传分类图片(140X140)">
								<template v-if="record.data.category_img">
									<img v-decorator="[
										'category_img',
										{
										  initialValue: record.data.category_img,
										  rules: [{ required: true, message: `请填写`, whitespace: true }],
										},
									  ]" 
									  style="width: 22%;height: 60%;cursor: pointer;" :src="record.data.category_img" alt="" @click="openresources(3)">
								</template>
								<template v-else>
									<div v-decorator="[
										'category_img',
										{
										  initialValue: record.data.category_img,
										  
										},
									  ]"
									style="width:35%;height: 30%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(3)">
										<a-icon type="plus" />
										<div class="ant-upload-text">
											上传图片
										</div>
									</div>
								</template>
							</a-form-item>
						</div>
						
						
						
						
						
						
						
						
						<!-- 轮播图 -->
						<a-form-item label="点击展示更换轮图片(750X520) 多图上传">
							
							<template v-if="record.data.carousel_img">
								<template v-if="record.data.carousel_img.length>0">
								<div class="f-ac">
									<template v-for="(item,index) in record.data.carousel_img">
										<div style="position: relative;width: 22%;">
											<a-icon style="position: absolute;top: 0;right: 0;" type="delete"  @click="delcarimg(index)"/>
											<img v-decorator="[
													'photo_urls',
													{
													  initialValue: record.data.carousel_img,
													  rules: [{ required: true, message: `请填写` }],
													},
												  ]"
												style="width: 100%;height: 60%;cursor: pointer;margin-right: 15px;" :src="item" alt="" @click="openresources(4,index)">
										</div>
									</template>
									<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="mt-30 upload f-ajc" @click="openresources(4)">
										<a-icon type="plus" />
										<div class="ant-upload-text">
											
											添加图片
										</div>
									</div>
								</div>

								</template>
								<template v-else>
									<div style="width: 25%;height: 60%;border:1px dashed #CCCCCC;flex-direction: column;padding: 30px;cursor: pointer;" class="upload f-ajc" @click="openresources(4)">
										<a-icon type="plus" />
										<div class="ant-upload-text"
											v-decorator="[
												'icon',
												{
												  initialValue: '',
												  rules: [{ required: true, message: `请填写`, whitespace: true }],
												},
											  ]"
											>
											上传图片
										</div>
									</div>
								</template>	
							</template>						  
						</a-form-item>
						
						<!-- 图片管理器 -->
						<resources ref="resources"  @getimgpath="getimgpath"></resources>
						
						<!-- 接单时间 -->
						 
						<a-form-item label="接单开始时间">
							<a-time-picker @change="getstarttime" format="HH:mm:ss" :minute-step="30" :second-step="60" v-decorator="[
								'start_time',
								{
								  initialValue: starttime,
								  rules: [{ required: true, message: `请选择开始时间`}],
								},
							  ]"/>
						</a-form-item>
						
						<a-form-item label="接单结束时间">
							<a-time-picker @change="getendtime" format="HH:mm:ss" :minute-step="30" :second-step="60" v-decorator="[
								'end_time',
								{
								  initialValue: endtime,
								  rules: [{ required: true, message: `请选择结束时间`}],
								},
							  ]"/>
						</a-form-item>
						
						<!-- 现价 -->
						<a-form-item label="现价">
							<a-input v-decorator="[
								'prices',
								{
								  initialValue: record.data.price,
								  rules: [{ required: true, message: `请填写名称`, whitespace: true }],
								},
							  ]" />
							  
						</a-form-item>
			
						<!-- 单位 -->
						<a-form-item label="单位">
							<a-select @change="getselect" v-decorator="[
								'unit',
								{ 
									initialValue: record.unit[0],
									rules: [{ required: true, message: `请选择单位` }],
								},
							  ]">
								
								<a-select-option v-for="(item,index) in record.unit" :key="index">
									{{item}}
								</a-select-option>
											
							</a-select>
						</a-form-item>
			
						<!-- 金额模式 -->
						<a-form-item label="金额模式">
							<a-radio-group v-decorator="[
								'deposit',
								{ 
									initialValue:1,
								},
							  ]" button-style="solid">
							  <a-radio-button :value="1">
							    定金
							  </a-radio-button>
							  <a-radio-button :value="2">
							    定额
							  </a-radio-button>
							</a-radio-group>
						</a-form-item>
			
						<!-- 描述 -->
						<a-form-item label="描述">
							<a-input v-decorator="[
								'desc',
								{
								  initialValue: record.data.desc,
								  rules: [{ required: true, message: `请填写描述`, whitespace: true }],
								},
							  ]" />
						</a-form-item>
						
						<!-- 搜索关键字 -->
						<a-form-item label="搜索关键字">
							<a-input v-decorator="[
								'keyword',
								{
								  initialValue: record.data.keyword,
								  rules: [{ required: true, message: `请填写关键字`, whitespace: true }],
								},
							  ]" />
						</a-form-item>
						
						<!-- 终点地址 -->
						<a-form-item label="终点地址（用于搬运）">
							    <a-radio-group
									 v-decorator="['end_address',{initialValue: 2,},]">
							      <a-radio :value="1">
							       是
							      </a-radio>
							      <a-radio :value="2">
							        否
							      </a-radio>
							    </a-radio-group>
						</a-form-item>
						
						<!-- 规格选择 -->
						<a-form-item label="规格模板">
							    <a-radio-group v-decorator="['template',{initialValue: record.data.template}]" @change="templateSwitch">
							      <a-radio value="complex" >
							       模板规格A（日常保洁类型）
								  <img class="imgxxx"  width="200px" src="http://oa.51tiaoyin.com/static/common/template/20210318112436.png" >
							      </a-radio>
							      <a-radio value="alone">
							        模板规格B（搬运类型）
								  <img class="imgxxx"  width="200px" src="http://oa.51tiaoyin.com/static/common/template/20210318112446.png" >
							      </a-radio>
								  <a-radio value="multiple">
								    模板规格C（家电清洗类型）
									<img class="imgxxx"  width="200px" src="http://oa.51tiaoyin.com/static/common/template/20210318112452.png" >
								  </a-radio>
								  <a-radio value="more">
								    模板规格D（多规格类型）
									<img class="imgxxx"  width="200px" src="http://oa.51tiaoyin.com/static/common/template/20210318112456.png" >
								  </a-radio>
								  <a-radio value="formaldehyde">
								    模板规格E（单规格类型）
									<img class="imgxxx"  width="200px" src="http://oa.51tiaoyin.com/static/common/template/20210319161254.png" >
								  </a-radio>
							    </a-radio-group>
								
								
						</a-form-item>
						<!-- 地区设置 -->
						<a-button type="primary" @click="handleAdd">
						  新增地区设置
						</a-button>
						<template v-for="(item,index) in record.data.specificationAll">
							<a-collapse default-active-key="0" :bordered="false" :expand-icon-position="expandIconPosition">
							      <a-collapse-panel :key="index" :header="'区域'+index">
										<!-- 地区选择 -->
											<div class="mt-15">
												<div class="city ">城市选择</div>
											  <a-checkbox    @change="checked=>getallcity(checked, val,index)">
												全选
											  </a-checkbox>
											  
											</div>
											<br />
											 <!-- <a-checkbox-group class="mb-30" v-model="item.cityAll" :options="record.area"  @change="getcity"/> -->
											 <a-checkbox-group v-model="item.cityAll">
											     <a-checkbox
											     	:disabled="chekedCityAll.indexOf(val)>-1&&item.cityAll.indexOf(val)<0?true:false"
											     	chekedCityAll v-for="(val, key) in record.area" :label="val" :key="key" :value="val"
											     	@change="checked=>checkRow(checked, val)">{{ val }}
											     </a-checkbox>
											 </a-checkbox-group>
											 <!-- 搬运 -->
											 <template v-if="record.data.template == 'alone'">
												 <template v-for="(items,indexs) in item.alone">
													 规格设置
													 <div class="specset f-acjsb">
													 	<a-form-item label="名称">
													 		<a-input v-model="items.name"/>
													 	</a-form-item>
													 	<a-form-item label="价格">
															<a-input v-model="items.price"/>
													 	</a-form-item>
														 <a-form-item label="操作">
															 <a-icon type="delete" @click="twoDelete(index,indexs)" />
															 <a-icon v-if="item.alone.length - 1 === indexs" type="plus" @click="twoAdd(index)"/>
														 </a-form-item>
													 </div>
												 </template>
											 </template>
											 
											 <!-- 日常保洁 -->
											 <template v-if="record.data.template== 'complex'">
														日常保洁
														规格设置
												  <Complex :records="record" :complexdata="item.complex" :indexs="index"></Complex>
												   
										
													 
													 
											 </template>
											 
											 <!--家电清洗类型  -->
											 <template v-if="record.data.template== 'multiple'">
												 家电清洗类型
													 <a-form-item label="标题名称">
														<a-input v-model="record.data.spec_title"/>
													 </a-form-item>
													 <template v-for="(items,indexs) in item.multiple">
														 规格设置
														
														 <div class="specset f-acjsb">
															<a-form-item label="图片（建议大小：148*128）">
																<img v-model="items.img" style="width: 22%;height: 60%;cursor: pointer;" :src="items.img || defaultimg" alt="" @click="openimg(index,indexs)">
															</a-form-item>
															
															<a-form-item label="名称">
																<a-input v-model="items.name"/>
															</a-form-item>
															 <a-form-item label="单价">
																 <a-input v-model="items.price"/>
															 </a-form-item>
															 <a-form-item label="最小">
																 <a-input v-model="items.min"/>
															 </a-form-item>
															 <a-form-item label="最大">
																 <a-input v-model="items.max"/>
															 </a-form-item>
															 <a-form-item label="默认">
																 <a-input v-model="items.default"/>
															 </a-form-item>
															 <a-form-item label="单位" width="200px" >
															 	 <div style="width: 150px;">
															 		 <a-select width="200" v-model="items.unit" @change="getunitall">
															 		 	<a-select-option v-for="(itemss,indexss) in record.unitAll" :value="itemss.id" :key="itemss.id">
															 		 		{{itemss.unit}}
															 		 	</a-select-option>
															 		 				
															 		 </a-select>
															 	 </div>
															  </a-form-item>
															 <a-form-item label="操作">
																 <a-icon type="delete" @click="twoDelete(index,indexs)" />
																 <a-icon v-if="item.multiple.length - 1 === indexs" type="plus" @click="twoAdd(index)"/>
															 </a-form-item>
														 </div>

													 </template>
													 <!-- 图片管理器 -->
													 <resources ref="resourcess"  @getimgpath="getimg"></resources>
			<!-- 										 <template v-if="isimg">
													 	<resources @getimgpath="getimg"></resources>
														<resources ref="resources"  @getimgpath="getimgpath"></resources>
														<resources ref="resourcess"  @getimgpath="getimg"></resources>
													 </template> -->
											 </template>
											 
											 
											 <!--多规格类型  -->
											 <template v-if="record.data.template== 'more'">
													多规格类型
												 <template v-for="(items,indexs) in item.more">
													 规格设置
													 
													 <div class="specset f-acjsb">

														 <a-form-item label="名称">
															 <a-input v-model="items.name"/>
														 </a-form-item>
														 <a-form-item label="单价">
															 <a-input v-model="items.price"/>
														 </a-form-item>
														 <a-form-item label="最小">
															 <a-input v-model="items.min"/>
														 </a-form-item>
														 <a-form-item label="最大">
															 <a-input v-model="items.max"/>
														 </a-form-item>
														 <a-form-item label="默认">
															 <a-input v-model="items.default"/>
														 </a-form-item>
														 <a-form-item label="单位" width="200px" >
														 	 <div style="width: 150px;">
														 		 <a-select width="200" v-model="items.unit" @change="getunitall">
														 		 	<a-select-option v-for="(itemss,indexss) in record.unitAll" :value="itemss.id" :key="itemss.id">
														 		 		{{itemss.unit}}
														 		 	</a-select-option>
														 		 				
														 		 </a-select>
														 	 </div>
														  </a-form-item>
														 <a-form-item label="操作">
															 <a-icon type="delete" @click="twoDelete(index,indexs)" />
															 <a-icon v-if="item.more.length - 1 === indexs" type="plus" @click="twoAdd(index)"/>
														 </a-form-item>
													 </div>
												 </template>
											 </template>
											 <!-- 单规格类型 -->
											 <template v-if="record.data.template== 'formaldehyde'">
											 		单规格类型
												 <template v-for="(items,indexs) in item.formaldehyde">
													 规格设置
													 <div class="specset f-acjsb">
														 <a-form-item label="名称">
															 <a-input v-model="items.name"/>
														 </a-form-item>
														 <a-form-item label="标题">
															 <a-input v-model="items.title"/>
														 </a-form-item>
														 <a-form-item label="单价">
															 <a-input v-model="items.price"/>
														 </a-form-item>
														 <a-form-item label="最小">
															 <a-input v-model="items.min"/>
														 </a-form-item>
														 <a-form-item label="最大">
															 <a-input v-model="items.max"/>
														 </a-form-item>
														 <a-form-item label="默认">
															 <a-input v-model="items.default"/>
														 </a-form-item>
														 <a-form-item label="单位" width="200px" >
														 	 <div style="width: 150px;">
														 		 <a-select width="200" v-model="items.unit" @change="getunitall">
														 		 	<a-select-option v-for="(itemss,indexss) in record.unitAll" :value="itemss.id" :key="itemss.id">
														 		 		{{itemss.unit}}
														 		 	</a-select-option>
														 		 				
														 		 </a-select>
														 	 </div>
														  </a-form-item>
														 <a-form-item label="操作">
															 <a-icon type="delete" @click="twoDelete(index,indexs)" />
															 <a-icon v-if="item.formaldehyde.length - 1 === indexs" type="plus" @click="twoAdd(index)"/>
														 </a-form-item>
													 </div>
												 </template>
											 </template>
									  <a-icon slot="extra" type="delete" @click="deleteClick(index)" />
							      </a-collapse-panel>
							 </a-collapse>
						</template>	
						
						<a-form-item style="position: relative;">
							<resources ref="resourcesss"  @getimgpath="getimgs"></resources>
							<!-- 定位元素 -->
							<div @click="pouploadimg(6,6)" style="position: absolute; top: 0; left: 684px;width: 40px;height: 41px;background: #0077AA;z-index: 99999999;opacity: 0;"></div>
							<tinymce 
							ref="editor" 
							@onClick="onClick"
							v-model="record.data.details">
							
							</tinymce>
						</a-form-item>
					</a-form>
			
					<!-- 提交变更按钮 -->
					<div class="modifybtn f-ajc mb-30">
						<a-button type="primary" @click="comfirm">
						  增加商品
						</a-button>
						<a-button class="ml-30" type="primary" @click="cancels">
						  取消
						</a-button>
					</div>

				</template>
			
			
			</div>
		</template>
		
	</div>
</template>

<script>
	import resources from "../../resoureces.vue"
	import moment from "moment"
	import tinymce from "../../common/tinymce.vue"
	import Complex from "./complex.vue"
	export default {
		components: {
			resources,
			tinymce,
			Complex
		},
		props: {
			record: Object
		},

		data() {
			return {
				chekedCityAll:[],
				defaultimg:"http://oa.51tiaoyin.com/themes/admin_simpleboot3/public/assets/images/default-thumbnail.png",
				jiaindex:"",
				jiaindexs:"",
				isimg:false,
                expandIconPosition:'left',
				show: false,
				isresources: false,
				previewVisible: false,
				checkAll: false,
				witchbtn:1,
				index:0,
				itemcity:"",//每个item城市
				once:1,
				specindex:0,
				previewImage: '',
				template:1,
				form: this.$form.createForm(this, {
					name: 'coordinated'
				}),
				
				// 日常保洁
				columns : [
				  {
				    title: '名称',
				    dataIndex: 'name',
				    scopedSlots: { customRender: 'name' },
				  },
				  {
				    title: '描述',
				    dataIndex: 'desc',
				    scopedSlots: { customRender: 'desc' },
				  },
				  {
				    title: '优惠则扣',
				    dataIndex: 'discount',
				    scopedSlots: { customRender: 'discount' },
				  },
				  {
				    title: '单次价格',
				    dataIndex: 'price',
				    scopedSlots: { customRender: 'price' },
				  },
				  {
				    title: '长期卡',
				    dataIndex: 'cycle',
					slots: { title: 'cycle' }, //插槽列
				    scopedSlots: { customRender: 'cycle' },
				  },
				  {
				    title: '次卡',
				    dataIndex: 'times',
					slots: { title: 'times' }, //插槽列
				    scopedSlots: { customRender: 'times' },
				  },
				  {
					  title: '操作',
					  dataIndex: 'operation',
					  scopedSlots: { customRender: 'operation' },
				  },
				],
				cyclecolumns:[
					  {
						title: '名称',
						dataIndex: 'name',
						scopedSlots: { customRender: 'name' },
					  },
					  {
						title: '周',
						dataIndex: 'week',
						scopedSlots: { customRender: 'week' },
					  },
					  {
						title: '折扣',
						dataIndex: 'discount',
						scopedSlots: { customRender: 'discount' },
					  },
					  {
						title: '单次金额',
						dataIndex: 'univalent',
						scopedSlots: { customRender: 'univalent' },
					  },
					  {
						title: '操作',
						dataIndex: 'action',
						scopedSlots: { customRender: 'action' },
					  }
				],
				timescolumns:[
					{
						title: '次数',
						dataIndex: 'times',
						scopedSlots: { customRender: 'times' },
					},
					{
						title: '折扣',
						dataIndex: 'discount',
						scopedSlots: { customRender: 'discount' },
					},
					{
						title: '单次金额',
						dataIndex: 'univalent',
						scopedSlots: { customRender: 'univalent' },
					},
					{
						title: '操作',
						dataIndex: 'action',
						scopedSlots: { customRender: 'action' },
					}
				],
				editingKey:"",
				cacheData:"",
				//统一定义规格的数据格式
                format: {
                    alone: {name: '', price: ''},
                    multiple: {
                        img: '',
                        imgshow: '/themes/admin_simpleboot3/public/assets/images/default-thumbnail.png',
                        name: '',
                        price: '',
                        min: '',
                        max: '',
                        default: '',
                        unit: '',
                    },
                    more: {name: '', price: '', min: '', max: '', default: '', unit: ''},
                    formaldehyde: {name: '', title: '服务点位', price: '', min: '', max: '', default: '', unit: ''},
                    complex: {
                        name: '',
                        desc: '',
                        discount: '',
                        price: '',
                        cycle: [{name: '', week: '', discount: '', univalent: ''}],
                        times: [{times: '', discount: '', univalent: ''}]
                    }
                },
				myTemplate:'',
			}
		},

		computed:{
			// 商品分类
			shopclass(){
				if(this.record.classify && this.record.data){
					let id = ""
					this.record.classify.map(item=>{
						console.log(this.record.data.category_top_id,"当前顶级分类");
						if(item.id == this.record.data.category_top_id){
							console.log(item,"二级分类");
							id = item.children.filter(items=>{
								return items.id == this.record.data.category_id
							})
						}
						
					})
					console.log(id,"分类id");
					return id[0].id
					
				}
			},
			// 时间转换
			starttime(){
				if(this.record.data){
					console.log(moment(this.record.data.start_time,'HH:mm:ss'));
					return moment(this.record.data.start_time,'HH:mm:ss')
				}
			},
			endtime(){
				if(this.record.data){
					console.log(moment(this.record.data.end_time,'HH:mm:ss'));
					return moment(this.record.data.end_time,'HH:mm:ss')
				}
			},
		},
		created() {

		},
		methods: {
			// 选中取消
			checkRow(c, t) {
				if (c.target.checked) {
					this.chekedCityAll.push(t)
				
			   } else {
				   this.chekedCityAll.splice(this.chekedCityAll.indexOf(t), 1);
		
			   }
			},
			// 获取类型
			gettype(e) {
				// console.log(e, "类型");
				this.type = e.target.value.toString() 
				console.log(this.type, "类型");
			},
			// 获取商品
			getcascader(e) {
				console.log(e, "获取商品");
			},
			// 获取分类
			getselect(e) {
				console.log(e, "获取选中分类id");
			},
			getunitall(e){
				console.log(e, "获取选中单位id");
			},
			// 打开图片管理器
			openresources(btn,index) {
				console.log(index,"dianji点击值");
				this.witchbtn  = btn
				this.index = index
				this.$refs.resources.visibles = true
			},
			openimg(index,indexs){
				this.isimg = true
				this.jiaindex = index
				this.jiaindexs = indexs
				console.log(this.$refs.resourcess,"haha");
				this.$refs.resourcess[0].visibles = true
				// this.isresources = true
			},
			pouploadimg(index,indexs){
				console.log("打开富文本图片");
				this.jiaindex = index
				this.jiaindexs = indexs
				console.log(this.$refs.resourcesss);
				this.$refs.resourcesss.visibles = true
			},
			// 获取富文本图片
			getimgs(val){
				let payload = {
					val:val,
					witchbtn:6,
					index:this.jiaindex,
					indexs:this.jiaindexs
				}
				this.$emit("changeimg", payload)
				// this.record.data.specificationAll[this.jiaindex].multiple[this.jiaindexs].img = val
				// console.log(this.record.data.specificationAll[this.jiaindex].multiple,"富文本图片");
			},
			// 删除图片
			delcarimg(index){
				console.log(index);
				this.record.data.carousel_img.splice(index,1)
			},
			// 获取家电清洗
			getimg(val){
				let payload = {
					val:val,
					witchbtn:5,
					index:this.jiaindex,
					indexs:this.jiaindexs
				}
				this.$emit("changeimg", payload)
				// this.record.data.specificationAll[this.jiaindex].multiple[this.jiaindexs].img = val
				// console.log(this.record.data.specificationAll[this.jiaindex].multiple,"家电清洗图片");
			},
			// 获取更改图片路径
			getimgpath(val) {
				// 收集数据集
				let payload = {
					val:val,
					witchbtn:this.witchbtn,
					index:this.index
				}
				this.$emit("changeimg", payload)
			},
			// 获取开始时间
			getstarttime(time, timeString){
				
				console.log(time, timeString);
				// this.record.data.start_time = moment(timeString,'HH:mm:ss')
				this.record.data.start_time = timeString
			},
			getendtime(time, timeString){
				console.log(time, timeString);
				// this.record.data.end_time = moment(timeString,'HH:mm:ss')
				this.record.data.end_time = timeString
			},
			// 选择城市列表
			getcity(index){
				
			},
			// 全选
			getallcity(c,t,index) {
				if(c.target.checked){
					this.record.data.specificationAll.map((itemval,indexval)=>{
						if(indexval == index){
							itemval.cityAll=this.record.area
						}else{
							itemval.cityAll = []
							console.log("其他子项数据",itemval.cityAll,indexval);
						}
					})
					// this.record.data.specificationAll[index].cityAll=this.record.area
					this.record.area.map(item=>{
						console.log(item)
						this.chekedCityAll.push(item)
						
					})
					
				}else{
					this.record.data.specificationAll[index].cityAll=[]
					this.chekedCityAll = [];
				}
			},
			
			handleChange(value, key, column) {
			  const newData = [...this.record.data.specificationAll[this.specindex].complex];
			  const target = newData.filter(item => key.name === item.name)[0];
			  if (target) {
				target[column] = value;
				console.log(target,"修改值");
				this.record.data.specificationAll[this.specindex].complex = newData;
			  }
			},
			//最外层添加
			handleAdd() {
			    let myTemplate = this.record.data.template;//当前选中的模板
                let format = JSON.parse(JSON.stringify(this.format));
                let newData = format[myTemplate];
                let obj = {};
                obj[myTemplate] = [newData];
                obj.cityAll = [];
                this.record.data.specificationAll.push(obj)
                console.log(this.record.data.specificationAll)
            },
			
			onClick(e){
				console.log(this.form,"编辑器");
				// this.record.data.details = e.
			},
			// 日常保洁方法
			// 取消提交返回
			cancels(){
				this.$emit("cancelss")
			},
			// 获取时间
			comfirm(e) {
				console.log(e);
				const form = this.$refs.collectionForm.form;
				form.validateFields((err, values) => {
					if (err) {
						return;
					}
					values.details = this.record.data.details
					values.specificationAll = this.record.data.specificationAll
					values.start_time =  this.record.data.start_time
					values.end_time =  this.record.data.end_time
					values.category_id = values.category_id
					console.log('Received values of forms: ', values)
					// for(let item in values){
					// 	console.log(item,"对象每一项");
					// 	if(values.item == ""){
					// 		console.log(values.item,"为空值");
					// 		return this.$message.error("填完不要留空")
					// 	}
					// }
					// console.log(this.record.data.specificationAll,"传递=值")
					// 执行提交方法
					this.$emit("modifynav", values)
				});
			},
			// 
			//最外层删除
            deleteClick(index){
                this.record.data.specificationAll.splice(index,1)
			},
			//二级添加
            twoAdd(index){
                let myTemplate = this.record.data.template;//当前选中的模板
                let format = JSON.parse(JSON.stringify(this.format));
                let newData = format[myTemplate];
                this.record.data.specificationAll[index][myTemplate].push(newData)
			},
			//二级删除
            twoDelete(index,indexs){
                let myTemplate = this.record.data.template;//当前选中的模板
                if (this.record.data.specificationAll[index][myTemplate].length==1){
                    this.record.data.specificationAll.splice(indexs,1)
                }else {
                    this.record.data.specificationAll[index][myTemplate].splice(indexs, 1);
                }
			},
			//模板切换
            templateSwitch(e){

				this.record.data.template = e.target.value
                let myTemplate = this.record.data.template;//当前选中的模板
                let format = JSON.parse(JSON.stringify(this.format));
                let newData = format[myTemplate];
                let obj = {};
                obj[myTemplate] = [newData];
                obj.cityAll = [];
				this.chekedCityAll = []
                this.record.data.specificationAll = [obj];
                console.log(this.record.data.template)
			}
		}
	}
</script>

<style scoped>
	.ant-radio-group {
	    display: flex;
	}
	.imgxxx{
		display: block;
	}
	.ant-upload-select-picture-card i {
	  font-size: 32px;
	  color: #999;
	}
	
	.ant-upload-select-picture-card .ant-upload-text {
	  margin-top: 8px;
	  color: #666;
	}
	.modifybtn{
		position: fixed;
		bottom: 0;
		width: 100%;
		
	}
</style>
